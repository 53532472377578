import { required } from 'vuelidate/lib/validators'

export default {
    data () {
        return {
            initialValues: {},
            skipCheckForChanges: false,
        }
    },
    props: {
        supplierProductImportedId: {
            default: null,
        },
        supplierProductImportedDetails: {
            default: null,
        },
        isSavingProduct: {
            default: false,
        },
        supplierProductImportStatus: {
            default: null,
        },
        supplierProductImportedDataSample: {
            default: () => {},
        },
        isGettingImportedProductDetails: {
            default: false,
        },
        isLoading: {
            default: false,
        },
    },
    methods: {
        saveProduct (data) {
            return new Promise((resolve, reject) => {
                this.$emit('save', {
                    ...this.payload,
                    ...(data || {}),
                    onSuccess: resolve,
                    onError: reject,
                })
            })
        },
    },
    computed: {
        isNew () {
            return !this.$route.params.supplierProductImportedId || this.$route.params.supplierProductImportedId === 'new'
        },
    },
    beforeRouteLeave (to, from, next) {
        if (!this.checkForChanges || !this.checkForChanges()) {
            next()
            return
        }

        const answer = window.confirm('Do you really want to leave? Unsaved changes will be lost!')
        if (answer) {
            next()
        } else {
            this.$emit('setStep', this.$route)
            next(false)
        }
    },
}

export const validations = {
    columnMapping: {
        required,
        $each: {
            mappedTo: {
                required,
            },
        },
        minimumAttributes (mapping) {
            let count = 0
            if (!Array.isArray(mapping)) {
                return false
            }
            for (let i = 0; i < mapping.length; i++) {
                if (mapping[i].type === 'ATTRIBUTE') {
                    count += Object.keys(mapping[i].mappedTo).length
                }
                if (count > 2) {
                    return true
                }
            }
            return false
        },
        isColumnMappingValid (mapping) {
            if (!mapping) {
                return true
            }

            const tempArray = mapping.map(e => {
                return [...e.columnNames]
            })
            const columnNames = [].concat.apply([], tempArray)
            return columnNames.every(e => this.supplierProductImportedDetails.columnNames.includes(e))
        },
        hasRequiredDataMapped (mapping) {
            if (!Array.isArray(mapping)) {
                return false
            }

            const allMappedData = mapping.filter(mapSet => mapSet.type === 'DATA').flatMap(e => Object.keys(e.mappedTo))

            return [
                'COST',
                'QUANTITY',
                'PRODUCTION_DAY',
                'WEIGHT',
                'PACKS',
                'SUPPLIER_SKU',
                'SUPPLIER_REFERENCE',
                'HS_CODE',
            ].every((e) => allMappedData.includes(e))
        },
        usedAllAttributes (mapping) {
            if (!Array.isArray(mapping)) {
                return false
            }
            if (!this.supplierProductImportedDetails || !this.supplierProductImportedDetails.attributeConfiguration) {
                return false
            }
            const requiredAttributes = Object.keys(this.supplierProductImportedDetails.attributeConfiguration)
            const allMappedAttributes = mapping.flatMap(e => Object.keys(e.mappedTo))
            return requiredAttributes.every((e) => allMappedAttributes.includes(e))
        },
        allUniqueMercurAttributes (mapping) {
            let attributeNames = []
            mapping.forEach(mapping => {
                if (mapping.type !== 'ATTRIBUTE') {
                    return
                }
                attributeNames.push(Object.keys(mapping.mappedTo))
            })
            attributeNames = attributeNames.flat().sort()

            const duplicateAttributeNames = []
            for (let i = 0; i < attributeNames.length - 1; i++) {
                if (attributeNames[i + 1] === attributeNames[i]) {
                    duplicateAttributeNames.push(attributeNames[i])
                }
            }
            return duplicateAttributeNames.length === 0
        },
        allUniqueAttributeColumns (mapping) {
            const columnNames = mapping.filter(mapSet => mapSet.type === 'ATTRIBUTE').flatMap(e => e.columnNames.join(',')).sort()
            const duplicateNames = columnNames.filter((name, index) => columnNames.indexOf(name) !== index)

            return duplicateNames.length === 0
        },
        allUniqueDataColumns (mapping) {
            const columnNames = mapping.filter(mapSet => mapSet.type === 'DATA').flatMap(e => e.columnNames.join(',')).sort()
            const duplicateNames = columnNames.filter((name, index) => columnNames.indexOf(name) !== index)

            return duplicateNames.length === 0
        },
    },
}
